import React from "react";
import cn from "classnames";

import { getIsMonitor } from "src/utils";
import { useSpring } from "@react-spring/web";

import { View } from "./types";

import styles from "./style.module.scss";

export const useContent = () => {
  const isMonitor = getIsMonitor();

  const [view, setView] = React.useState<View>(
    isMonitor ? "broadcast" : "statistics"
  );

  const getContentProps = React.useCallback(
    () => ({
      className: cn(styles.content),
      "data-testid": view === "broadcast" ? "div-video" : "div-statistics",
      onClick: () => {
        if (isMonitor) {
          setView((value) => (value === "broadcast" ? "statistics" : "broadcast"));
        }
      },
    }),
    [isMonitor, view]
  );

  const { transform, opacity } = useSpring({
    opacity: view === "broadcast" ? 1 : 0,
    transform: `rotateX(${view === "broadcast" ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  const getPlayerProps = React.useCallback(
    () => ({
      className: cn(styles.animated),
      style: {
        opacity,
        rotateX: "180deg",
        transform,
      },
    }),
    [opacity, transform]
  );

  const getStatisticsProps = React.useCallback(
    () => ({
      className: cn(styles.animated),
      style: {
        opacity: opacity.to((o) => 1 - o),
        transform,
      },
    }),
    [opacity, transform]
  );

  const values = React.useMemo(
    () => ({
      isMonitor,
    }),
    [isMonitor]
  );

  return { getContentProps, getPlayerProps, getStatisticsProps, values };
};
