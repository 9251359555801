import React from "react";

import { State } from "./types";
import { tutorialActions } from "src/slices";
import { useAppDispatch, useContent } from "src/hooks";

let intervalId: NodeJS.Timer;

export const useMobile = () => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const getButtonProps = React.useCallback(
    (className: string) => ({
      className,
      "data-testid": "button-faq-close",
      onClick: () => dispatch(tutorialActions.tutorialUpdated(false)),
    }),
    [dispatch]
  );

  const [state, setState] = useState();

  const getCarouselProps = React.useCallback(
    () => ({
      onChange: (index: number) => {
        if (index === state.index) {
          return;
        }
        setState(() => ({ index, mode: "play", time: 0 }));
      },
      onSwipeEnd: () => {
        setState((value) => ({ ...value, mode: "play" }));
      },
      onSwipeStart: () => {
        setState((value) => ({ ...value, mode: "pause" }));
      },
      selectedItem: state.index,
      showArrows: false,
      showIndicators: false,
      showStatus: false,
      showThumbs: false,
    }),
    [state]
  );

  const values = React.useMemo(
    () => ({
      content,
      state,
    }),
    [content, state]
  );

  return { getButtonProps, getCarouselProps, values };
};

export const useState = () => {
  const dispatch = useAppDispatch();

  const [state, setState] = React.useState<State>({
    index: 0,
    mode: "play",
    time: 0,
  });

  React.useEffect(() => {
    switch (state.mode) {
      case "pause":
        clearInterval(intervalId);
        break;
      case "play":
        intervalId = setInterval(
          () => setState((value) => ({ ...value, time: value.time + 100 })),
          100
        );
        break;
    }
    return () => clearInterval(intervalId);
  }, [dispatch, state]);

  React.useEffect(() => {
    if (state.time === 4000) {
      if (state.index === 5) {
        dispatch(tutorialActions.tutorialUpdated(false));
      } else {
        setState((value) => ({ ...value, index: value.index + 1, time: 0 }));
      }
    }
  }, [dispatch, state]);

  return [state, setState] as const;
};
