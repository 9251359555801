import React from "react";
import cn from "classnames";

import { Props } from "./types";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useTime = (props: Props) => {
  const content = useContent();

  const [time, setTime] = React.useState<number>(0);

  const workerHandler = React.useCallback((value: number) => setTime(value), []);

  const worker = useWorker(workerHandler);

  React.useEffect(() => {
    const game = props.game;

    if (game && game.status === "expected") {
      const now = new Date().getTime();
      const time = Math.abs(now - game.expect);

      setTime(time);

      worker.postMessage({ event: "drop" });
      worker.postMessage({ event: "start", start: time });
    }
  }, [props.game, worker]);

  const getTimeProps = React.useCallback(
    () => ({
      className: cn(styles[content], {
        [styles.ready]: time < 10000 && time >= 4000,
        [styles.steady]: time < 4000,
      }),
    }),
    [content, time]
  );

  const getScProps = React.useCallback(
    () => ({
      className: cn(styles.sc),
      "data-testid": "expect-seconds",
    }),
    []
  );

  const getMsProps = React.useCallback(
    () => ({
      className: cn(styles.ms),
      style: {
        width: "2.175rem",
      } as React.CSSProperties,
    }),
    []
  );

  const date = new Date(time);
  const sc = `${String(date.getSeconds()).padStart(2, "0")}.`;
  const ms = `${String(Math.floor(date.getMilliseconds() / 10)).padEnd(2, "0")}`;

  const values = React.useMemo(
    () => ({
      sc,
      ms,
    }),
    [ms, sc]
  );

  return { getMsProps, getScProps, getTimeProps, values };
};

export const useWorker = (callback: (value: number) => void) => {
  const worker = React.useMemo(
    () => new Worker(new URL("./worker.ts", import.meta.url)),
    []
  );

  worker.onmessage = (ev: MessageEvent) => {
    callback(ev.data.time);
  };

  return worker;
};
