import React from "react";

import { useSelector } from "react-redux";

import { getTheme } from "src/selectors";
import { tutorialActions } from "src/slices";
import { useAppDispatch, useContent } from "src/hooks";

export const useCombined = () => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const theme = useSelector(getTheme);

  const getButtonProps = React.useCallback(
    (className: string) => ({
      className,
      "data-testid": "button-faq-close",
      onClick: () => dispatch(tutorialActions.tutorialUpdated(false)),
    }),
    [dispatch]
  );

  const values = React.useMemo(
    () => ({
      content,
      theme,
    }),
    [content, theme]
  );

  return { getButtonProps, values };
};
