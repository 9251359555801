import React from "react";
import cn from "classnames";

import styles from "./style.module.scss";

export const useContent = () => {
  const getContentProps = React.useCallback(
    () => ({
      className: cn(styles.content),
      "data-testid": "app-ready",
    }),
    []
  );

  return { getContentProps };
};
