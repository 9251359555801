import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { AudioContext } from "src/contexts";
import { betsUndoThunk, betsAddedX2Thunk, betsRepeatedThunk } from "src/thunks";
import { createGetIsAnyGame, getNextId, getTheme } from "src/selectors";
import {
  createGetIsDoubleDisabled,
  createGetIsRepeatDisabled,
} from "src/components/control/model";
import { Props } from "./types";
import { useAppDispatch, useContent } from "src/hooks";

import style from "./style.module.scss";

export const useControl = (props: Props) => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const nextId = useSelector(getNextId);
  const theme = useSelector(getTheme);

  const isNextTechBreak = useSelector(createGetIsAnyGame([nextId], ["tech_break"]));
  const isDoubleDisabled = useSelector(createGetIsDoubleDisabled(nextId));
  const isRepeatDisabled = useSelector(createGetIsRepeatDisabled(nextId));
  const isUndoDisabled = isDoubleDisabled;

  const { play } = React.useContext(AudioContext);

  const text = React.useMemo(() => {
    switch (props.type) {
      case "double":
        return "УДВОИТЬ";
      case "repeat":
        return "ПОВТОРИТЬ";
      case "undo":
        return "ОТМЕНИТЬ";
      default:
        return false;
    }
  }, [props.type]);

  const testId = React.useMemo(() => {
    switch (props.type) {
      case "double":
        return "button-x2";
      case "repeat":
        return "button-repeat";
      case "undo":
        return "button-undo";
      default:
        return false;
    }
  }, [props.type]);

  const disabled = React.useMemo(() => {
    switch (props.type) {
      case "double":
        return isDoubleDisabled || isNextTechBreak;
      case "repeat":
        return isRepeatDisabled || isNextTechBreak;
      case "undo":
        return isUndoDisabled || isNextTechBreak;
      default:
        return false;
    }
  }, [
    isDoubleDisabled,
    isNextTechBreak,
    isRepeatDisabled,
    isUndoDisabled,
    props.type,
  ]);

  const onClick = React.useCallback(() => {
    play("control");
    switch (props.type) {
      case "double":
        dispatch(betsAddedX2Thunk());
        break;
      case "repeat":
        dispatch(betsRepeatedThunk());
        break;
      case "undo":
        dispatch(betsUndoThunk());
        break;
      default:
        break;
    }
  }, [dispatch, play, props.type]);

  const getButtonProps = React.useCallback(
    () => ({
      className: cn(
        style[content],
        style[theme],
        style[props.type],
        props.className && style[props.className]
      ),
      "data-testid": testId,
      disabled,
      onClick,
    }),
    [content, disabled, onClick, props.className, props.type, testId, theme]
  );

  const values = React.useMemo(
    () => ({
      text,
    }),
    [text]
  );

  return { getButtonProps, values };
};
