import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { createGetBetsByParams, createGetGameById, getGameId } from "src/selectors";
import { metrikaActions } from "src/actions";
import { Props } from "./types";
import { tutorialActions } from "src/slices";
import { useAppDispatch, useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useHint = (props: Props) => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const game = useSelector(createGetGameById(props.gameId));
  const gameId = useSelector(getGameId);

  const bets = useSelector(
    createGetBetsByParams(
      [props.gameId],
      [],
      ["confirmed", "finish", "pending", "selected"]
    )
  );

  const getHintProps = React.useCallback(
    () => ({
      className: cn(styles[content], {
        [styles.active]: bets.length === 0,
      }),
    }),
    [bets.length, content]
  );

  const onClick = React.useCallback(() => {
    dispatch(tutorialActions.tutorialUpdated(true));
    dispatch(metrikaActions.send({ target: "TutorialClick" }));
  }, [dispatch]);

  const getButtonProps = React.useCallback(
    () => ({
      className: cn(styles.button),
      "data-testid": `button-faq-${gameId === props.gameId ? "curr" : "next"}`,
      onClick,
    }),
    [gameId, onClick, props.gameId]
  );

  const values = React.useMemo(
    () => ({
      game,
      styles,
    }),
    [game]
  );

  return { getButtonProps, getHintProps, values };
};
