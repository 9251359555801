import React from "react";
import cn from "classnames";

import { Props } from "./types";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useLine = (props: Props) => {
  const content = useContent();

  const getLineProps = React.useCallback(
    () => ({
      className: cn(styles[content], styles[`color-${props.line}`], {
        [styles.even]: props.line % 2 === 0,
      }),
      "data-testid": props.testId,
      onClick: props.onClick,
    }),
    [content, props]
  );

  return { getLineProps };
};
