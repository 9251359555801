import React from "react";

import { useSelector } from "react-redux";

import { AudioContext } from "src/contexts";
import { betsAddedByLineThunk } from "src/thunks";
import { createGetBetsByParams, createGetIsAnyGame, getNextId } from "src/selectors";
import { metrikaActions } from "src/actions";
import { sort } from "./utils";
import { useAppDispatch } from "src/hooks";

import styles from "./styles.module.scss";

export const useDesktop = () => {
  const dispatch = useAppDispatch();

  const nextId = useSelector(getNextId);

  const bets = useSelector(createGetBetsByParams([nextId], ["competition"], []));
  const isNextTechBreak = useSelector(createGetIsAnyGame([nextId], ["tech_break"]));

  const { play } = React.useContext(AudioContext);

  const getButtonProps = React.useCallback(
    (line: number, invert: boolean) => ({
      "data-testid": `button-line-${invert ? "col" : "row"}-${line}`,
      onClick: () => {
        play("odd");
        dispatch(betsAddedByLineThunk(line, invert));
        dispatch(metrikaActions.send({ target: "LineRowClick" }));
      },
      disabled: isNextTechBreak,
    }),
    [dispatch, isNextTechBreak, play]
  );

  const values = React.useMemo(
    () => ({
      styles,
      competitions: sort(bets),
    }),
    [bets]
  );

  return { getButtonProps, values };
};
