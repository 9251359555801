import { BALANCES } from "./constants";
import { Contractor } from "./types";

export const getBalanceId = () => {
  const balanceId = sessionStorage.getItem("balanceId") || "0";

  return parseInt(balanceId) || 0;
};

export const getTimeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getLocale = (value: number, id: number) => {
  return `${value.toLocaleString("ru-RU")} ${BALANCES[id]}`;
};

export const getIsMonitor = () => {
  const url = new URLSearchParams(window.location.search);

  let result = true;

  if (url.has("monitor")) {
    const monitor = url.get("monitor");
    result = monitor === "false" ? true : false;
  }

  return result;
};

export const getContractor = () => {
  const url = new URLSearchParams(window.location.search);

  let contractor: Contractor = "offline";

  if (url.has("contractor")) {
    contractor = url.get("contractor") as Contractor;
  }

  return contractor;
};

export const getSoundMuted = () => {
  const url = new URLSearchParams(window.location.search);

  let soundMuted = 1;

  if (url.has("sound_muted")) {
    soundMuted = parseInt(url.get("sound_muted") || "1");
  }

  return soundMuted;
};
