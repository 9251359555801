import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getContractor, getSoundMuted } from "src/utils";
import { socketActions } from "src/actions";
import { State } from "./types";

const contractor = getContractor();
const soundMuted = getSoundMuted();

const isOnline = contractor === "online" || contractor === "demo";
const volume = soundMuted === 0 ? 0.5 : 0;

const STATE: State = {
  content: "desktop",
  theme: "default",
  version: "2.3",
  volume: 0,
};

const STATE_ONLINE: State = {
  content: "v3",
  theme: "default",
  version: "3.0",
  volume,
};

export const contentSlice = createSlice({
  name: "content",
  initialState: isOnline ? STATE_ONLINE : STATE,
  reducers: {
    volumeUpdated: (state, action: PayloadAction<number>) => {
      if (isOnline) {
        state.volume = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(socketActions.messageInit, (state, action) => {
      switch (action.payload.payload.version) {
        case "2.3":
          state.content = "desktop";
          state.theme = "default";
          state.version = "2.3";
          break;
        case "3.0":
          state.content = "v3";
          state.theme = "default";
          state.version = "3.0";
          break;
        case "3.1":
          state.content = "v3";
          state.theme = "dark";
          state.version = "3.1";
          break;
        case "3.2":
          state.content = "v3";
          state.theme = "markets";
          state.version = "3.2";
          break;
      }

      if (isOnline) {
        state.content = "v3";
        state.theme = "default";
        state.version = "3.0";
      }
    });
  },
});

export const contentActions = {
  volumeUpdated: contentSlice.actions.volumeUpdated,
};
