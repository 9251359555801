import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";
import { useTransition } from "@react-spring/web";

import { AudioContext, CouponsSwipeContext } from "src/contexts";
import { couponsActions } from "src/slices";
import { createGetBetsByParams, getGameId, getNextId, getView } from "src/selectors";
import { useAppDispatch, useContent } from "src/hooks";
import { View } from "src/types";

import styles from "./style.module.scss";

export const useHeader = () => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const gameId = useSelector(getGameId);
  const nextId = useSelector(getNextId);

  const gameBets = useSelector(
    createGetBetsByParams(
      [gameId],
      [],
      ["confirmed", "finish", "pending", "selected"]
    )
  );
  const nextBets = useSelector(
    createGetBetsByParams(
      [nextId],
      [],
      ["confirmed", "finish", "pending", "selected"]
    )
  );
  const view = useSelector(getView);

  const { play } = React.useContext(AudioContext);
  const { handlers, onUpdate, refHead } = React.useContext(CouponsSwipeContext);

  const getHeaderProps = React.useCallback(
    () => ({
      ...handlers,
      className: cn(styles[content]),
      ref: refHead,
    }),
    [content, handlers, refHead]
  );

  const getGameProps = React.useCallback(
    (value: View) => ({
      className: cn(styles.game, {
        [styles.selected]: value === view,
      }),
      onClick: () => {
        play("tab");
        dispatch(couponsActions.viewUpdated(value));
        if (value === view) {
          onUpdate();
        }
      },
    }),
    [dispatch, onUpdate, play, view]
  );

  const getButtonProps = React.useCallback(
    (value: View) => ({
      className: cn(styles.button, {
        [styles.selected]: value === view,
      }),
      ["data-selected"]: value === view ? "true" : "false",
      "data-testid": `game-${value}`,
      onClick: () => {
        play("tab");
        dispatch(couponsActions.viewUpdated(value));
      },
    }),
    [dispatch, play, view]
  );

  const queueTransition = useQueueTransition(gameId, nextId);

  const values = React.useMemo(
    () => ({
      content,
      counts: [gameBets.length, nextBets.length],
      gameId,
      queue: queueTransition,
      styles,
    }),
    [content, gameBets, gameId, nextBets, queueTransition]
  );

  return { getButtonProps, getGameProps, getHeaderProps, values };
};

export const useQueueTransition = (gameId: number, nextId: number) => {
  const [queue, setQueue] = React.useState<Array<number>>([]);

  React.useEffect(() => setQueue([gameId, nextId]), [gameId, nextId]);

  return useTransition(
    queue.map((id, index) => ({ id, index })),
    {
      keys: queue.map((id) => id),
      from: ({ index }) => ({ x: `${index * 100 + 100}%` }),
      enter: ({ index }) => ({ x: `${index * 100}%` }),
      update: ({ index }) => ({ x: `${index * 100}%` }),
      leave: ({ index }) => ({ x: `${index * 100 - 100}%` }),
      config: { duration: 500 },
    }
  );
};
