import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { getTheme } from "src/selectors";
import { Props } from "./types";
import { useContent } from "src/hooks";

import styles from "./style.module.scss";

import Border from "src/components/status/assets/images/common/border.svg";

export const useStatus = (props: Props) => {
  const content = useContent();

  const theme = useSelector(getTheme);

  const testId = React.useMemo(() => {
    switch (props.game.status) {
      case "started":
        return "div-status-start";
      case "play":
      case "finished":
        return "div-status-play";
      case "expected":
        return "div-status-expect";
      default:
        return "div-status-empty";
    }
  }, [props.game.status]);

  const getStatusProps = React.useCallback(
    () => ({
      className: cn(styles[content], styles[theme]),
      "data-testid": testId,
    }),
    [content, testId, theme]
  );

  const getGameProps = React.useCallback(
    () => ({
      game: props.game,
    }),
    [props.game]
  );

  const getLapsProps = React.useCallback(
    () => ({
      game: props.game,
    }),
    [props.game]
  );

  const getTimeProps = React.useCallback(
    () => ({
      game: props.game,
    }),
    [props.game]
  );

  const getBorderProps = React.useCallback(
    () => ({
      className: cn(styles.border),
      src: Border,
    }),
    []
  );

  return {
    getBorderProps,
    getGameProps,
    getLapsProps,
    getTimeProps,
    getStatusProps,
  };
};
